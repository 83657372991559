($ => {
    const $weight = $('.enter-weight__input');

    $($weight).on('keyup', function() {
        if (($(this).val()) > 30) {
            $('.enter-weight').addClass('is-weight');
            $('.enter-weight__300mg').show();
            $('.enter-weight__150mg').hide();
            $('.enter-weight__below150mg').hide();
        } else if (($(this).val()) <= 30 && ($(this).val()) >= 15 || !$(this).val()) {
            $('.enter-weight').removeClass('is-weight');
            $('.enter-weight__300mg').hide();
            $('.enter-weight__150mg').show();
            $('.enter-weight__below150mg').hide();
        } else if (($(this).val()) < 15) {
            $('.enter-weight').removeClass('is-weight');
            $('.enter-weight__300mg').hide();
            $('.enter-weight__150mg').hide();
            $('.enter-weight__below150mg').show();
        }
    });


})(jQuery);
